import volunteeringEndpoints from "./endpoints/volunteering-endpoints";
import apiConfig from "../config/api";
import http from "./http";
import store from "../store/index";

const {
  getVolunteers,
  getVolunteer,
  addVolunteer,
  editVolunteer,
  deleteVolunteer,
  addVolunteerActivity,
  editVolunteerActivity,
  getVolunteerActivities,
  getVolunteerActivity,
  deleteVolunteerActivity,
} = volunteeringEndpoints;
const { baseURL } = apiConfig;

const Volunteering = {
  async getVolunteers() {
    const getVolunteersURL = `${baseURL}${getVolunteers.GET()}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getVolunteersURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async editVolunteer(volunteer) {
    const getEditVolunteerUrl = `${baseURL}${editVolunteer.GET(volunteer.id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .put(
        getEditVolunteerUrl,
        {
          id: volunteer.id,
          firstName: volunteer.firstName,
          lastName: volunteer.lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async addVolunteer(volunteer) {
    const getAddVolunteerUrl = `${baseURL}${addVolunteer.GET}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .post(
        getAddVolunteerUrl,
        {
          firstName: volunteer.firstName,
          lastName: volunteer.lastName,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async addVolunteerActivity(volunteerActivity) {
    const getAddVolunteerActivityUrl = `${baseURL}${addVolunteerActivity.GET}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .post(
        getAddVolunteerActivityUrl,
        {
          activityTypeId: volunteerActivity.activityTypeId,
          hours: volunteerActivity.hours,
          volunteerId: volunteerActivity.volunteerId,
          startDateTime: volunteerActivity.startDateTime,
          endDateTime: volunteerActivity.endDateTime,
          description: volunteerActivity.description,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getVolunteer(id) {
    const getVolunteerURL = `${baseURL}${getVolunteer.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getVolunteerURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getVolunteerActivity(id) {
    const getVolunteerActivityURL = `${baseURL}${getVolunteerActivity.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getVolunteerActivityURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async editVolunteerActivity(volunteerActivity) {
    const getEditVolunteerActivitiyUrl = `${baseURL}${editVolunteerActivity.GET(
      volunteerActivity.id
    )}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .put(
        getEditVolunteerActivitiyUrl,
        {
          id: volunteerActivity.id,
          startDateTime: volunteerActivity.startDateTime,
          endDateTime: volunteerActivity.endDateTime,
          hours: volunteerActivity.hours,
          description: volunteerActivity.description,
          volunteerId: volunteerActivity.volunteerId,
          activityTypeId: volunteerActivity.activityTypeId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async getVolunteerActivities(id) {
    const getVolunteerActivitiesURL = `${baseURL}${getVolunteerActivities.GET(
      id
    )}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .get(
        getVolunteerActivitiesURL,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async deleteVolunteer(id) {
    const getDeleteVolunteerUrl = `${baseURL}${deleteVolunteer.GET(id)}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .delete(
        getDeleteVolunteerUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
  async deleteVolunteerActivity(id) {
    const getDeleteVolunteerActivityUrl = `${baseURL}${deleteVolunteerActivity.GET(
      id
    )}`;
    http.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${store.state.token}`;
    return await http
      .delete(
        getDeleteVolunteerActivityUrl,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `token ${store.state.token}`,
          },
        }
      )
      .catch((error) => {
        return error;
      });
  },
};

export default Volunteering;
