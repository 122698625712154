export default Object.freeze({
  getVolunteers: {
    GET: () => {
      return `volunteers/volunteers`;
    },
  },
  addVolunteer: {
    GET: "volunteers/volunteer",
  },
  editVolunteer: {
    GET: (id) => {
      return `volunteers/${id}`;
    },
  },
  getVolunteer: {
    GET: (id) => {
      return `volunteers/${id}`;
    },
  },
  deleteVolunteer: {
    GET: (id) => {
      return `volunteers/${id}`;
    },
  },
  getVolunteerActivities: {
    GET: (id) => {
      return `volunteers/${id}/activities`;
    },
  },
  getVolunteerActivity: {
    GET: (id) => {
      return `volunteeractivities/${id}`;
    },
  },

  editVolunteerActivity:{
    GET: (id) => {
      return `volunteeractivities/${id}`;
    },
  },
  deleteVolunteerActivity: {
    GET: (id) => {
      return `volunteeractivities/${id}`;
    },
  },
  addVolunteerActivity: {
    GET: "volunteers/volunteersactivity",
  },
});
