<template>
  <div>
    <modal-box
      :is-active="isModalActive"
      :trash-object-name="trashObjectName"
      @confirm="trashConfirm"
      @cancel="trashCancel"
    />
    <b-table
      v-if="volunteers"
      :loading="isLoading"
      :paginated="paginated"
      :per-page="perPage"
      :striped="true"
      :hoverable="true"
      default-sort="name"
      :data="volunteers"
    >
      <b-table-column
        custom-key="actions"
        cell-class="is-actions-cell"
        v-slot="props"
      >
        <div class="buttons is-left">
          <router-link
            :to="{
              name: 'volunteer.edit',

              params: { id: props.row.id },
            }"
            class="button is-small is-primary"
          >
            <b-icon icon="file-edit" size="is-small" />
          </router-link>

          <button
            class="button is-small is-danger"
            type="button"
            @click.prevent="trashModal(props.row)"
            v-if="canDelete(props.row.id)"
          >
            <b-icon icon="trash-can" size="is-small" />
          </button>
        </div>
      </b-table-column>
      <template v-for="column in columns">
        <b-table-column :key="column.id" v-bind="column">
          <template
            v-if="column.searchable && !column.numeric"
            #searchable="props"
          >
            <b-input
              v-model="props.filters[props.column.field]"
              placeholder="Cauta..."
              icon="magnify"
              size="is-small"
            />
          </template>
          <template v-if="column.date" v-slot="props">
            {{ props.row[column.field] | formatDate }}
          </template>
          <template v-else v-slot="props">
            {{ props.row[column.field] }}
          </template>
        </b-table-column>
      </template>
      <b-table-column
        label="Ore de voluntariat disponibile/(Calcul in zile)"
        field="hours"
        sortable
        v-slot="props"
        right
      >
        <div class="buttons is-small">
          <router-link
            :to="`/volunteers/${props.row.id}/activities/add`"
            class="button is-small is-primary"
          >
            Adauga activitate</router-link
          >
          <router-link
            :to="{ name: 'volunteerActivities', params: { id: props.row.id } }"
            class="button is-small is-info"
          >
            {{ props.row.hours }} ore / ({{
              Math.floor(props.row.hours / 8)
            }}
            zile lucratoare, {{ props.row.hours % 8 }} ore)
          </router-link>
        </div>
      </b-table-column>
      <section class="section" slot="empty">
        <div class="content has-text-grey has-text-centered">
          <template v-if="isLoading">
            <p>
              <b-icon icon="dots-horizontal" size="is-large" />
            </p>
            <p>Incarcare data...</p>
          </template>
          <template v-else>
            <p>
              <b-icon icon="emoticon-sad" size="is-large" />
            </p>
            <p>Nici o inregistrare&hellip;</p>
          </template>
        </div>
      </section>
    </b-table>
  </div>
</template>
<script>
import ModalBox from "@/components/ModalBox";

export default {
  name: "VolunteersTable",
  components: { ModalBox },
  props: {
    volunteers: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      isModalActive: false,
      trashObject: null,
      trashObjectName: null,
      clients: [],
      isLoading: false,
      paginated: true,
      perPage: 10,
      checkedRows: [],
      columns: [
        {
          field: "id",
          label: "ID",
          numeric: true,
          visible: false,
        },
        {
          field: "lastName",
          label: "Nume",
          numeric: false,
          sortable: true,
          searchable: true,
          visible: true,
        },
        {
          field: "firstName",
          label: "Prenume",
          numeric: false,
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  methods: {
    canDelete(currentId) {
      return true;
    },
    trashModal(trashObject) {
      this.trashObject = trashObject;
      this.isModalActive = true;
    },
    trashConfirm() {
      this.isModalActive = false;
      this.$emit("handleDeleteVolunteer", this.trashObject.id);
    },
    trashCancel() {
      this.isModalActive = false;
    },
  },
};
</script>
