<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      <b-button class="button is-primary" @click="handleAddVolunteer">
        Adauga voluntar
      </b-button>
    </hero-bar>
    <section class="section is-main-section">
      <card-component
        class="has-table has-mobile-sort-spaced"
        title="Voluntari"
        icon="account-multiple"
      >
        <volunteers-table
          :volunteers="volunteers"
          @handleDeleteVolunteer="handleDeleteVolunteer"
        ></volunteers-table>
      </card-component>
      <hr />
    </section>
  </div>
</template>

<script>
import Volunteering from "@/services/volunteering.service";
const _ = require("lodash");
import CardComponent from "@/components/CardComponent";
import TitleBar from "@/components/TitleBar";
import HeroBar from "@/components/HeroBar";
import VolunteersTable from "@/components/volunteering/volunteers-table.vue";

export default {
  name: "Volunteering",
  components: {
    HeroBar,
    TitleBar,
    CardComponent,
    VolunteersTable,
  },
  data() {
    return {
      volunteers: [],
    };
  },
  created() {
    this.getAllVolunteers()
  },
  methods: {
    async getAllVolunteers() {
      const { getVolunteers } = Volunteering;
      const response = await getVolunteers();
      const { data } = response;
      return data;
    },
    async handleAddVolunteer() {
      this.$router.push({
        path: "/volunteers/add",
      });
    },
    async handleDeleteVolunteer(id) {
      const { deleteVolunteer } = Volunteering;
      const response = await deleteVolunteer(id);
      const { data, status } = response;
      if (status == "200") {
        const array = this.volunteers;
        const index = this.volunteers.findIndex((prop) => prop.id === id);
        this.volunteers.splice(index, 1);
        this.$buefy.snackbar.open({
          message: "Inregistrea a fost stearsa.",
          queue: false,
        });
      } else {
      }
    },
  },
  computed: {
    titleStack() {
      return ["Registru Voluntariat", "Voluntari"];
    },
    heroRouterLinkTo() {
      return { name: "/volunteers/add" };
    },
  },
};
</script>
